import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';
import PositionDroppable from './PositionDroppable/PositionDroppable';
import getAllDimensions from '../../../utils/data/getAllDimensions';
import config from '../../../config';

const { POSITION_ROW, POSITION_COL } = config;

/**
 * Dimensions organizer container
 * @param       {Bool}     props.enabled                on/off
 * @param       {Object[]} props.dimensionsData         list of unique dimensions
 * @param       {Object}   props.dimensionsPosition     dimensions current position
 * @param       {Function} props.setDimensionsPosition  saves positions to app state
 * @constructor
 */
function OrganizeDataDnd(props) {
  const { t } = useTranslation();
  const [emptyPositionId, setEmptyPositionId] = useState(null);

  const mergedDimensions = useMemo(() => {
    return getAllDimensions(props.dimensionsData);
  }, [props.dimensionsData]);

  // build row position dimension list
  const rowDimensions = useMemo(() => {
    return props.dimensionsPosition[POSITION_ROW].map(dimId =>
      mergedDimensions.find(dim => dim.id === dimId)
    );
  }, [mergedDimensions, props.dimensionsPosition]);

  // build col position dimension list
  const colDimensions = useMemo(() => {
    return props.dimensionsPosition[POSITION_COL].map(dimId =>
      mergedDimensions.find(dim => dim.id === dimId)
    );
  }, [mergedDimensions, props.dimensionsPosition]);

  /**
   * Drag finish handler
   * @param  {Object} result
   */
  const onDragEnd = result => {
    const { dimensionsPosition } = props;
    const { destination, source, draggableId } = result;
    let newDimensionsPosition;

    // validations
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    // get source position array
    const sourcePosition = Array.from(dimensionsPosition[source.droppableId]);
    // remove item
    sourcePosition.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      // reordering => inserts in same array
      sourcePosition.splice(destination.index, 0, parseInt(draggableId));

      newDimensionsPosition = {
        ...dimensionsPosition,
        [source.droppableId]: sourcePosition
      };
    } else {
      // movement => inserts in other array
      const destinationPosition = Array.from(
        dimensionsPosition[destination.droppableId]
      );
      destinationPosition.splice(destination.index, 0, parseInt(draggableId));

      newDimensionsPosition = {
        ...dimensionsPosition,
        [source.droppableId]: sourcePosition,
        [destination.droppableId]: destinationPosition
      };
    }

    // store empty position, if any
    if (!sourcePosition.length) {
      setEmptyPositionId(parseInt(source.droppableId));
    } else {
      emptyPositionId && setEmptyPositionId(null);
    }

    props.setDimensionsPosition(newDimensionsPosition);
  };

  // validation
  if (!mergedDimensions.length) return null;

  const Title = ({ children }) => (
    <span className="d-block pb-1">{children}</span>
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {/* <Title>{t('header')}</Title>
      <PositionDroppable
        id={POSITION_HEADER}
        dimensionIds={props.dimensionsPosition[POSITION_HEADER]}
        mergedDimensions={mergedDimensions}
      /> */}

      <Title>{t('rows')}</Title>
      <PositionDroppable
        id={POSITION_ROW}
        dimensions={rowDimensions}
        disableDrag={!props.enabled}
        alert={emptyPositionId === POSITION_ROW}
      />

      <Title>{t('columns')}</Title>
      <PositionDroppable
        id={POSITION_COL}
        dimensions={colDimensions}
        disableDrag={!props.enabled}
        alert={emptyPositionId === POSITION_COL}
      />
    </DragDropContext>
  );
}

export default OrganizeDataDnd;

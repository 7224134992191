import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import Api from '../../../../utils/Api';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { BiRightArrow, BiDownArrow } from 'react-icons/bi';
import config from '../../../../config';
import getQsParams from '../../../../utils/getQsParams';

import './ThematicTree.scss';

/**
 * indicators tree
 * @param       {Bool}   showIds              whether to show ids of indicators
 * @param       {Function} setIsLeafSelected  to set whether the selected node is a leaf
 * @param       {Function} setSelectedIndicator to set the current selected indicator
 * @constructor
 */
function ThematicTree({ showIds, setIsLeafSelected, setSelectedIndicator }) {
  const [data, setData] = useState();

  useEffect(() => {
    async function loadData() {
      const params = getQsParams();
      const propArea = params.context === 'sdg' ? 'area_id' : 'xarea_id';

      const treeData = await Api.getThematicTree(undefined, {
        [propArea]: config.TREE_EXCLUDED_AREAS,
        hide: 1
      });
      setData(treeData[0]);
    }

    loadData();
  }, []);

  const onNodeClick = (indicatorId, areaId) => {
    setSelectedIndicator(indicatorId, areaId);
    setIsLeafSelected(!!indicatorId);
  };

  const RenderTree = ({ nodes, areaId }) => {
    const id = `${nodes.theme_id || nodes.area_id || nodes.indicator_id}`;

    const nodeLabel = (() => {
      let label = '';
      nodes.indicator_id && (label += '• ');
      nodes.indicator_id && showIds && (label += `[${id}] `);
      label += nodes.name;

      return label;
    })();

    return (
      <TreeItem
        key={id}
        nodeId={id}
        label={nodeLabel}
        onLabelClick={() =>
          onNodeClick(
            parseInt(nodes.indicator_id) || null,
            parseInt(areaId) || null
          )
        }
      >
        {Array.isArray(nodes.children) && nodes.children.length > 0
          ? nodes.children.map(node => <RenderTree nodes={node} areaId={nodes.area_id || null} />)
          : null}
      </TreeItem >
    );
  };

  return data ? (
    <TreeView
      className="indicators-tree"
      defaultExpanded={['27']}
      defaultCollapseIcon={<BiDownArrow size="0.7rem" />}
      defaultExpandIcon={<BiRightArrow size="0.7rem" />}
    >
      <RenderTree nodes={data} areaId={null} />
    </TreeView>
  ) : (
    <Spinner color="secondary" />
  );
}

export default ThematicTree;

import React, { useMemo } from 'react';
import PivotTable from 'react-pivottable/PivotTable';
import { sortAs } from 'react-pivottable/Utilities';
import config from '../../../config';
import scrollToElement from '../../../utils/scrollToElement';
import indentation from '../../../utils/data/childrenIndent';

import './TablePivot.scss';

const { POSITION_ROW, POSITION_COL } = config;

/**
 * Pivot table component
 * @param       {Object[]} props.allDimensions       list of merged dimensions
 * @param       {Object}   props.dimensionsPosition  position of dimensions
 * @param       {Object[]} props.data                table data
 * @param       {Number}   props.decimals            decimal places
 * @param       {Bool}     props.showNotes           include notes switch
 * @param       {Bool}     props.showSources         include sources switch
 * @param       {Bool}     props.forExport           show export output
 * @param       {string}   props.tableTitle          table title
 * @param       {Object}   props.tableFootnotes      sources and notes details
 * @param       {string}   props.tableCopyright      copyright
 * @constructor
 */
function TablePivot(props) {
  // define rows
  const rows = useMemo(() => {
    return props.dimensionsPosition[POSITION_ROW].map(dimId => `dim_${dimId}`);
  }, [props.dimensionsPosition]);

  // define columns
  const cols = useMemo(() => {
    return props.dimensionsPosition[POSITION_COL].map(dimId => `dim_${dimId}`);
  }, [props.dimensionsPosition]);

  // row headers
  const rowHeaders = useMemo(() => {
    return props.dimensionsPosition[POSITION_ROW].map(dimId => {
      const dimension = props.allDimensions.find(dim => dim.id === dimId);
      return dimension?.name || '';
    });
  }, [props.allDimensions, props.dimensionsPosition]);

  // column headers
  const colHeaders = useMemo(() => {
    return props.dimensionsPosition[POSITION_COL].map(dimId => {
      const dimension = props.allDimensions.find(dim => dim.id === dimId);
      return dimension?.name || '';
    });
  }, [props.allDimensions, props.dimensionsPosition]);

  // cell aggregator
  const cellValue = () => {
    return (data, rowKey, colKey) => {
      return {
        cell: 0,
        push: function (record) {
          let value = null;

          if ([...colKey, ...rowKey].includes('source'))
            value = record.source_id || null;
          else if ([...colKey, ...rowKey].includes('notes'))
            value = record.notes_ids || null;
          else {
            value = record;
          }

          if (this.cell === 0) this.cell = value;
          else {
            if (this.cell === value || this.cell?.value === value?.value)
              return;
            this.cell = null;
          }
        },
        value: function () {
          return this.cell;
        },
        format: function (x) {
          if (x == null) return null;

          // if not exporting, force numbers to fixed decimals
          if (!props.forExport) return parseFloat(x).toFixed(props.decimals);

          return x;
        }
      };
    };
  };

  // sorting object
  const sorters = (() => {
    let sorters = {};

    props.allDimensions.forEach(dimension => {
      // pass same names as returned by getDataRows
      const memberNames = dimension.members.map(
        member => indentation.repeat(member.level * 4) + member.name
      );
      sorters['dim_' + dimension.id] = sortAs(memberNames);
    });

    return sorters;
  })();

  return (
    <div className="table-wrapper w-100 overflow-auto mb-2">
      <PivotTable
        id={props.forExport ? 'table-export' : 'table-pivot'}
        className={`table table-sm table-bordered small ${
          props.forExport ? 'd-none' : ''
        }`}
        data={props.data}
        rows={rows}
        cols={cols}
        vals={['value']}
        aggregators={{ cellValue }}
        aggregatorName="cellValue"
        sorters={sorters}
        rowHeaders={rowHeaders}
        colHeaders={colHeaders}
        showSources={props.showSources}
        showNotes={props.showNotes}
        onSourceClick={id => scrollToElement(`source_${id}`)}
        onNoteClick={id => scrollToElement(`note_${id}`)}
        forExport={props.forExport || false}
        tableTitle={props.tableTitle}
        tableFootnotes={props.tableFootnotes}
        tableCopyright={props.tableCopyright}
      />
    </div>
  );
}

export default TablePivot;

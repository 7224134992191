import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { MdRemoveCircleOutline } from 'react-icons/md';

import './SelectedDropdown.scss';

/**
 * Dropdown list of selected members
 * @param       {Number}   dimensionId         dimension identifier
 * @param       {Object[]} members            members data
 * @param       {Function} setMemberSelection changes member's active property
 * @constructor
 */
function SelectedDropdown({ dimensionId, members, setMemberSelection }) {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const selectedMembers = members.filter(({ active }) => active);

  function handleItemClick(id, e) {
    setMemberSelection(dimensionId, id, false);
  }

  return (
    <Dropdown
      className="sel-dropdown"
      isOpen={dropdownOpen}
      size="sm"
      toggle={toggle}
      color="primary"
      title={t('selectedCategories')}
    >
      <DropdownToggle color="primary" className="px-1">
        {selectedMembers.length}
      </DropdownToggle>

      {selectedMembers.length ? (
        <DropdownMenu right>
          {selectedMembers.map(({ id, name }) => (
            <DropdownItem
              key={id}
              className="d-flex align-items-center px-2"
              onClick={() => handleItemClick(id)}
            >
              <MdRemoveCircleOutline className="text-muted mr-1" /> {name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      ) : null}
    </Dropdown>
  );
}

export default SelectedDropdown;

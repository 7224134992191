import { getLanguage } from './i18n';
import qs from 'qs';
import config from '../config';

class Api {
  /**
   * retrieves metadata from specific indicator
   * @param  {Number}  indicatorId indicator identifier
   * @param  {Object}  [params={}] querystring parameters
   * @return {Promise}
   */
  static async getMetadata(indicatorId, params = {}) {
    // build metadata url
    const url = `${config.ENDPOINT_INDICATOR}/${indicatorId}/metadata`;
    const data = await this.get(url, params);

    return data.body.metadata;
  }

  /**
   * fetches area information
   * @param  {Number}  areaId      area identifier
   * @param  {Object}  [params={}] querystring parameters
   * @return {Promise}
   */
  static async getAreas(indicatorId, params = {}) {
    // build url
    const url = `${config.ENDPOINT_INDICATOR}/${indicatorId}/areas`;
    const data = await this.get(url, params);

    return data.body.areas;
  }

  static extractMembers(members, result = []) {
    for (let member of members) {
      const { members: _, ...memberWithoutMembers } = member;
      result.push(memberWithoutMembers);
      if (member.members) {
        this.extractMembers(member.members, result);
      }
    }
    return result;
  }

  /**
   * fetch dimensions data from specific indicator
   * @param  {Number}  indicatorId indicator identifier
   * @param  {Object}  [params={}] querystring parameters
   * @return {Promise}
   */
  static async getDimensions(indicatorId, params = {}) {
    // build dimensions url
    const url = `${config.ENDPOINT_INDICATOR}/${indicatorId}/dimensions`;
    const data = await this.get(url, params);
    const dimensions = data.body.dimensions.map(({ members, ...other }) => ({
      ...other,
      members: members && this.extractMembers(members),
    }))
    return dimensions;
  }

  /**
   * fetches indicator data
   * @param  {Number}  indicatorId indicator identifier
   * @param  {Object}  [params={}] querystring parameters
   * @return {Promise}
   */
  static async getData(indicatorId, params = {}, isDowload) {
    // build dimensions url
    const url = `${config.ENDPOINT_INDICATOR}/${indicatorId}/data`;

    if (isDowload) {
      this.download(url, params);
      return;
    }

    const response = await this.get(url, params);
    const {
      metadata: { indicator_id },
      data,
      metadata,
      sources,
      footnotes
    } = response.body;

    return { indicator_id, data, metadata, sources, footnotes };
  }

  /**
   * fetches the indicators tree from server
   * @param  {Number}  [theme=27]  main theme
   * @param  {Object}  [params={}] querystring parameters
   * @return {Promise}
   */
  static async getThematicTree(theme = config.TREE_DEFAULT_THEME, params = {}) {
    // build tree url
    const url = `${config.ENDPOINT_THEMATIC_TREE}/${theme}`;
    const data = await this.get(url, params);

    return data.body;
  }

  /**
   * GET request
   * @param  {String}  url         URL to be appended to API URL
   * @param  {Object}  [params={}] querystring parameters
   * @return {Promise}             data
   */
  static async get(url, params = {}) {
    const response = await fetch(Api.buildUrl(url, params))
      .then(response => {
        if (response.status >= 400 && response.status < 600)
          throw new Error('Bad response from server');

        return response;
      })
      .catch(error => {
        throw new Error(error);
      });

    const data = await response.json();
    if (!response.ok) throw new Error(data.message);
    return data;
  }

  /**
   * download file request
   * @param  {String}  url         URL to be appended to API URL
   * @param  {Object}  [params={}] querystring parameters
   * @return {Promise}             file
   */
  static async download(url, params = {}) {
    window.open(Api.buildUrl(url, params));
  }

  /**
   * creates URL string using parameters
   * @param  {String} url         URL to be appended to API URL
   * @param  {Object} [params={}] querystring parameters
   * @return {String}             URL
   */
  static buildUrl(url, params = {}) {
    if (!params.lang) params.lang = getLanguage();
    if (!params.format) params.format = config.API_BASE_URL_FORMAT;
    const queryString = new URLSearchParams(params).toString();
    return `${config.API_BASE_URL}/${url}?${queryString}`;
  }
}

export default Api;

import indentation from './childrenIndent';

/**
 * returns data of all indicators in plain format
 * @param  {Object}   indicatorsData   data of all indicators
 * @param  {Object[]} dimensions    unique dimensions
 * @param  {Object}   metadataMapping  ids-letters mapping
 * @return {Object[]}                  data rows
 */
function getDataRows(
  indicatorsData,
  dimensions,
  metadataMapping,
  indent = false,
  decimalPlaces = 2
) {
  let rows = [];

  Object.keys(indicatorsData).forEach(indicId => {
    const indicator = indicatorsData[indicId];
    // deep copy data
    const indicatorData = JSON.parse(JSON.stringify(indicator.data));

    // sort by dimension properties
    indicatorData.sort((rowA, rowB) => {
      const order = dimensions.reduce((result, dim) => {
        // omit fake dim 0
        if (dim.id === 0 || result !== 0) return result;
        else {
          const key = 'dim_' + dim.id;

          const memberA = dim.members.find(m => m.id === rowA[key]);
          const memberB = dim.members.find(m => m.id === rowB[key]);

          if (!memberA || !memberB) {
            return result;
          } else {
            return memberA.order - memberB.order;
          }
        }
      }, 0);

      return order;
    });

    indicatorData.forEach((row, i) => {
      const sourceId = row.source_id;
      const notesIds = row.notes_ids;

      row.id = `${indicId}_${i}`;
      row.dim_0 = indicator.metadata.indicator_name;

      dimensions.forEach(dim => {
        // exclude indicator fake dimension
        if (dim.id === 0) return;

        const memberId = row[`dim_${dim.id}`] || null;

        if (memberId) {
          const member = dim.members.find(mem => mem.id === memberId);

          row[`dim_${dim.id}`] = member
            ? (indent ? indentation.repeat(member.level * 4) : '') + member.name
            : '...';
        } else {
          row[`dim_${dim.id}`] = 'n/a';
        }
      });

      // rounding
      const num = parseFloat(row.value);
      row.value = +(
        Math.round(num + 'e+' + decimalPlaces) +
        'e-' +
        decimalPlaces
      );

      // replace IDs with letters if activated
      if (metadataMapping) {
        if (sourceId !== '') row.source_id = metadataMapping.sources[sourceId];

        if (notesIds !== '')
          row.notes_ids = notesIds
            .split(',')
            .map(note => metadataMapping.notes[note])
            .join();
      }
    });

    rows.push(...indicatorData);
  });

  return rows;
}

export default getDataRows;

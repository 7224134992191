import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Input, Label, Button, Spinner } from 'reactstrap';
import getQsParams from '../../utils/getQsParams';

import AddIndicatorModal from './AddIndicatorModal/AddIndicatorModal';

import './IndicatorSelector.scss';

/**
 * Indicator management panel
 * @param       {Object[]} props.indicatorsList       indicators added
 * @param       {Number}   props.activeIndicatorId    active indicator identifier
 * @param       {Function} props.setActiveIndicator   sets the active indicator
 * @param       {Function} props.onAddIndicator       adds indicator
 * @param       {Function} props.onRemoveIndicator    removes the active indicator
 * @constructor
 */
export default function IndicatorSelector(props) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => setOpenModal(!openModal);

  const handleChange = e => props.setActiveIndicator(e.target.value);

  useEffect(() => {
    const params = getQsParams();
    !params.indicator_id && setOpenModal(true);
  }, []);

  return (
    <>
      <Container className="indic-selector border my-3">
        <Row className="mt-2">
          <Col xs="12">
            <Label for="indicator-select">{t('selectedIndicators')}</Label>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {props.indicatorsList.length ? (
              <Input
                className="overflow-auto border-0"
                type="select"
                name="SelectBox"
                id="indicator-select"
                size="3"
                value={props.activeIndicatorId}
                onChange={handleChange}
              >
                {props.indicatorsList.map(indicator => (
                  <option
                    key={indicator.indicator_id}
                    value={indicator.indicator_id}
                  >
                    {indicator.indicator_name}
                  </option>
                ))}
              </Input>
            ) : (
              <Spinner color="secondary" />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Button className="text-muted" color="link" onClick={toggleModal}>
              + {t('addIndicator')}
            </Button>

            <Button
              className="text-muted"
              color="link"
              disabled={!(props.indicatorsList.length > 1)}
              onClick={() => props.onRemoveIndicator(props.activeIndicatorId)}
            >
              - {t('removeIndicator')}
            </Button>
          </Col>
        </Row>
      </Container>

      <AddIndicatorModal
        modal={openModal}
        toggleModal={toggleModal}
        onAddIndicator={props.onAddIndicator}
      />
    </>
  );
}

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguage } from '../../utils/i18n';
import TableFlat from './TableFlat/TableFlat';
import TablePivot from './TablePivot/TablePivot';
import TableFooter from './TableFooter/TableFooter';
import getAllDimensions from '../../utils/data/getAllDimensions';
import getDataRows from '../../utils/data/getDataRows';
import StringIdGenerator from '../../utils/StringIdGenerator';
import config from '../../config';

const { TYPE_FLAT, TYPE_PIVOT } = config;

/**
 * Container for table, notes and sources
 * @param       {String} tableType            plain/pivot
 * @param       {Bool}   showTable            whether to show the table or not
 * @param       {Object} dimensionsData       dimensions of every indicator
 * @param       {Object} dimensionsPosition   dimensions position
 * @param       {Object} indicatorsData       data of all indicator
 * @param       {Bool}   showNotes            include notes switch
 * @param       {Bool}   showSources          include sources switch
 * @param       {Bool}   useLetters           use letters for metadata
 * @constructor
 */
function TableContainer(props) {
  const { t } = useTranslation();
  const metadataMapping = useRef({ sources: {}, notes: {} });

  const title = (() => {
    const dataIDs = Object.keys(props.indicatorsData);

    return dataIDs.length === 1
      ? props.indicatorsData[dataIDs[0]].metadata.indicator_name
      : null;
  })();

  const date = new Intl.DateTimeFormat(getLanguage()).format(new Date());

  // create sources full list and mapping
  const sources = (() => {
    const allSources = [];
    const Letters = new StringIdGenerator('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

    metadataMapping.current['sources'] = {};

    Object.keys(props.indicatorsData).forEach(indicId => {
      const indicSources = JSON.parse(
        JSON.stringify(props.indicatorsData[indicId].sources)
      );

      indicSources.forEach((src, i) => {
        if (props.useLetters) {
          const letter = Letters.next();

          metadataMapping.current['sources'][src.id] = letter;
          src.id = letter;
        }

        allSources.push(src);
      });
    });

    return allSources;
  })();

  // create notes full list and mapping
  const notes = (() => {
    const allNotes = [];
    const Letters = new StringIdGenerator('abcdefghijklmnopqrstuvwxyz');
    metadataMapping.current['notes'] = {};

    Object.keys(props.indicatorsData).forEach(indicId => {
      const indicNotes = JSON.parse(
        JSON.stringify(props.indicatorsData[indicId].footnotes)
      );

      indicNotes.forEach((note, i) => {
        if (props.useLetters) {
          const letter = Letters.next();

          metadataMapping.current['notes'][note.id] = letter;
          note.id = letter;
        }

        allNotes.push(note);
      });
    });

    return allNotes;
  })();

  const mergedDimensions = getAllDimensions(props.dimensionsData);

  // find maximum decimal places
  const decimalPlaces = Object.entries(props.indicatorsData).reduce(
    (acc, currData) => {
      const decimals = currData[1]?.metadata?.decimals || 0;
      return acc < decimals ? decimals : acc;
    },
    0
  );

  const data = (() => {
    const mapping = props.useLetters ? metadataMapping.current : null;
    const indent = props.tableType === TYPE_PIVOT;

    return getDataRows(
      props.indicatorsData,
      mergedDimensions,
      mapping,
      indent,
      decimalPlaces
    );
  })();

  return (
    <div className="table-container mb-3">
      {props.tableType === TYPE_FLAT ? (
        <TableFlat
          allDimensions={mergedDimensions}
          data={data}
          showNotes={props.showNotes}
          showSources={props.showSources}
        />
      ) : data?.length ? (
        <TablePivot
          allDimensions={mergedDimensions}
          dimensionsPosition={props.dimensionsPosition}
          data={data}
          decimals={decimalPlaces}
          showNotes={props.showNotes}
          showSources={props.showSources}
        />
      ) : (
        <div className="d-flex justify-content-center">{t('noDataFound')}</div>
      )}

      {data?.length ? (
        <TableFooter
          sources={props.showSources ? sources : null}
          notes={props.showNotes ? notes : null}
        />
      ) : null}

      {/* non-visible table used for exporting */}
      {props.tableType === TYPE_PIVOT ? (
        <TablePivot
          allDimensions={mergedDimensions}
          dimensionsPosition={props.dimensionsPosition}
          data={data}
          decimals={decimalPlaces}
          showNotes={props.showNotes}
          showSources={props.showSources}
          forExport={true}
          tableTitle={title}
          tableFootnotes={
            <TableFooter sources={sources} notes={notes} forExport={true} />
          }
          tableCopyright={t('copyright') + ` [${date}]`}
        />
      ) : null}
    </div>
  );
}

export default TableContainer;

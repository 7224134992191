import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem, Badge } from 'reactstrap';
import { getLanguage } from '../../utils/i18n';
import config from '../../config';
import getQsParams from '../../utils/getQsParams';

import './IndicatorHeader.scss';

/**
 * Header showing active indicator
 * @param       {Object} metadata indicator metadata
 * @constructor
 */
function IndicatorHeader({ metadata }) {
  const { t } = useTranslation();
  const path = metadata?.areaInfo?.path;

  function openLink(destination) {
    const lang = getLanguage();
    const params = getQsParams();
    const areaParam = metadata?.areaInfo?.area_id
      ? `&area_id=${metadata?.areaInfo?.area_id}`
      : '';
    let baseUrl = '';

    if (destination === 'cheatsheet') {
      if (params.context === 'sdg') {
        baseUrl = config.CHEATSHEET_SDG_URL;
      } else {
        baseUrl = config.CHEATSHEET_CEPALSTAT_URL;
      }
    } else if (destination === 'dashboard') {
      if (params.context === 'sdg') {
        baseUrl =
          lang === 'es'
            ? config.DASHBOARD_SDG_URL_ES
            : config.DASHBOARD_SDG_URL_EN;
      } else {
        baseUrl = config.DASHBOARD_CEPALSTAT_URL;
      }
    }

    const url = `${baseUrl}?indicator_id=${metadata?.indicator_id}${areaParam}&lang=${lang}`;

    window.open(url, '_blank');
  }

  return (
    <div className="indic-header">
      <Breadcrumb className="text-muted">
        {path ? (
          path
            .split('/')
            .map((dir, i) => (
              <BreadcrumbItem key={i}> {dir || '...'} </BreadcrumbItem>
            ))
        ) : (
          <>
            <BreadcrumbItem>{metadata?.theme || '...'}</BreadcrumbItem>
            <BreadcrumbItem>{metadata?.area || '...'}</BreadcrumbItem>
          </>
        )}
      </Breadcrumb>

      <div className="d-flex mb-2">
        <div>
          <h4 className="mr-2 mb-0">
            {/* NAME */}
            {metadata?.indicator_name || '...'}

            {/* FACTSHEET */}
            <Badge
              color="secondary"
              pill
              className="ml-2"
              onClick={() => openLink('cheatsheet')}
            >
              {t('factsheet')}
            </Badge>

            {/* DASHBOARD */}
            <Badge
              color="secondary"
              pill
              className="ml-2"
              onClick={() => openLink('dashboard')}
            >
              {t('dashboard')}
            </Badge>
          </h4>

          {/* UNIT */}
          <small className="text-muted font-italic">
            {`(${metadata?.unit})`}
          </small>
        </div>
      </div>
    </div>
  );
}

export default IndicatorHeader;

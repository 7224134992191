import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * footer showing source and notes
 * @param       {Object[]} sources  source list
 * @param       {Object[]} notes    note list
 * @param       {Boolean}  forExport  exporting mode
 * @constructor
 */
function TableFooter({ sources, notes, forExport }) {
  const { t } = useTranslation();
  const CustomTag = forExport ? 'tr' : 'div';

  // NOTE: needed to use <br>s to get correct export to excel
  return (
    <>
      <CustomTag></CustomTag>
      {sources ? (
        <>
          <CustomTag>
            <h6>{t('sources')}</h6>
          </CustomTag>

          {sources.map((source, i) => {
            return (
              <CustomTag key={i}>
                {/* <CustomTag style={{ lineHeight: '1px' }}>
                  <br />
                </CustomTag> */}
                <small
                  className="mb-1"
                  id={'source_' + source.id}
                  key={source.id}
                >
                  {`${source.id}) `}
                  <a
                    target="_blank"
                    href={source.organization_url}
                    rel="noopener noreferrer"
                  >
                    {source.organization_acronym} - {source.organization_name}
                  </a>
                  : {source.description}
                </small>
              </CustomTag>
            );
          })}
        </>
      ) : null}

      <CustomTag className="my-3"></CustomTag>

      {notes ? (
        <>
          <CustomTag>
            <h6>{t('notes')}</h6>
          </CustomTag>

          {notes.map((note, i) => {
            return (
              <CustomTag key={i}>
                {/* <CustomTag style={{ lineHeight: '1px' }}>
                  <br />
                </CustomTag> */}
                <small className="mb-1" id={'note_' + note.id} key={note.id}>
                  {note.id}) {note.note}
                </small>
              </CustomTag>
            );
          })}
        </>
      ) : null}
    </>
  );
}

export default TableFooter;

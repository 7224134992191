import isInViewport from './isInViewport';

/**
 * scrolls window to received ref
 * @param  {Object} elem  element to scroll to
 */
export default function scrollToElement(elem) {
  if (typeof elem === 'string') elem = document.getElementById(elem);

  if (!isInViewport(elem)) {
    elem.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }
}

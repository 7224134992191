import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import ThematicTree from './ThematicTree/ThematicTree';

import './AddIndicatorModal.scss';

/**
 * Modal window for adding an indicator
 * @param       {Bool}     modal          whether to open modal
 * @param       {Function} toggleModal    changes modal visibility
 * @param       {Function} onAddIndicator adds an indicator
 * @constructor
 */
function AddIndicatorModal({ modal, toggleModal, onAddIndicator }) {
  const { t } = useTranslation();
  const [isLeafSelected, setIsLeafSelected] = useState(false);
  const [showIds, setShowIds] = useState(true);
  // useRef is necessary here
  let selectedIndicatorId = useRef();
  let selectedIndicatorAreaId = useRef();

  const setSelectedIndicator = (indicatorId, areaId) => {
    selectedIndicatorId.current = indicatorId;
    selectedIndicatorAreaId.current = areaId;
  };

  const toggleShowIds = () => setShowIds(!showIds);

  const onAddButtonClick = () => {
    onAddIndicator(
      selectedIndicatorId.current,
      selectedIndicatorAreaId.current
    );
    toggleModal();
  };

  return (
    <Modal
      className="add-indic-modal"
      size="lg"
      scrollable={true}
      isOpen={modal}
      toggle={toggleModal}
    >
      <ModalHeader toggle={toggleModal}>{t('indicators')}</ModalHeader>
      <ModalBody>
        <ThematicTree
          showIds={showIds}
          setIsLeafSelected={setIsLeafSelected}
          setSelectedIndicator={setSelectedIndicator}
        />
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Label check className="small ml-4">
          <Input type="checkbox" checked={showIds} onChange={toggleShowIds} />{' '}
          {t('showIds')}
        </Label>
        <div>
          <Button outline color="secondary" onClick={toggleModal}>
            {t('cancel')}
          </Button>{' '}
          <Button
            color="primary"
            onClick={onAddButtonClick}
            disabled={!isLeafSelected}
          >
            {t('add')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default AddIndicatorModal;

/**
 * returns comparison function for dimension sorting
 * @param  {Object} valueA first value to compare
 * @param  {Object} valueB second value to compare
 * @return {Function}      comparison function
 */
export default function compareDimensions(valueA, valueB) {
  // id === 208   country
  // id === 29117 year
  return valueA.id === 208
    ? -1
    : valueA.id === 29117 && valueB.id !== 208
    ? -1
    : 1;
}

import i18n from '../i18n';
import config from '../../config';

const { POSITION_ROW } = config;

/**
 * returns an array of all unique dimensions
 * @param  {Object}    dimensionsData  main dimensions data
 * @return {Object[]}                  array of unique dimensions
 */
export default function getAllDimensions(dimensionsData) {
  let allDimensions = [];

  // deep copy dimensions object
  const dimensionsClone = JSON.parse(JSON.stringify(dimensionsData));
  // remove wrapping array
  let dimensionsArray = Object.values(dimensionsClone).flat(1);

  if (!dimensionsArray.length) return [];

  // exclude repeated dimensions with members merging
  dimensionsArray.forEach(currDimension => {
    const parsedDim = allDimensions.find(dim => dim.id === currDimension.id);

    if (parsedDim) {
      const ids = new Set(parsedDim.members.map(d => d.id));
      const mergedMembers = [
        ...parsedDim.members,
        ...currDimension.members.filter(d => !ids.has(d.id))
      ];
      parsedDim.members = mergedMembers;
    } else {
      allDimensions.push(currDimension);
    }
  });

  allDimensions.sort((a, b) => (a.order > b.order ? 1 : -1));

  allDimensions.unshift({
    id: 0,
    members: [],
    name: i18n.t('indicator'),
    order: 0,
    position: POSITION_ROW
  });

  return allDimensions;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'reactstrap';
import { MdInfoOutline } from 'react-icons/md';
import OptionBox from './OptionBox/OptionBox';
import TableSelector from './TableSelector/TableSelector';
import OrganizeDataDnd from './OrganizeDataDnd/OrganizeDataDnd';
import config from '../../config';

import './OptionContainer.scss';

const { TYPE_PIVOT } = config;

/**
 * Panel containing app options
 * @param       {Object} props
 * @param       {Object} props.tableType              flat / pivot type
 * @param       {Object} props.setTableType
 * @param       {Object} props.dimensionsData
 * @param       {Object} props.dimensionsPosition
 * @param       {Object} props.setDimensionsPosition
 * @constructor
 */
function OptionContainer(props) {
  const { t } = useTranslation();

  return (
    <Container className="option-container bg-lightgray mb-2">
      <Row>
        <OptionBox title={t('options')}>
          <TableSelector
            tableType={props.tableType}
            setTableType={props.setTableType}
          />
        </OptionBox>
      </Row>
      <Row>
        <OptionBox
          title={
            <>
              {t('organizeData')}{' '}
              <MdInfoOutline title={t('organizeDataInfo')} />
            </>
          }
        >
          <OrganizeDataDnd
            enabled={props.tableType === TYPE_PIVOT}
            dimensionsData={props.dimensionsData}
            dimensionsPosition={props.dimensionsPosition}
            setDimensionsPosition={props.setDimensionsPosition}
          />
        </OptionBox>
      </Row>
    </Container>
  );
}

export default OptionContainer;

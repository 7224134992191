import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col, FormGroup, Card, CardTitle } from 'reactstrap';
import SelectedDropdown from './SelectedDropdown/SelectedDropdown';
import MemberSelector from './MemberSelector/MemberSelector';

import './DimensionBox.scss';

const CheckLevel = ({ level, onChange, checkState }) => {
  const onCheckLevelHandler = ({ target: { checked } }) => {
    onChange({ level, checked })
  }

  return (
    <Col>
      <input
        type="checkbox"
        ref={(el) => {
          if (el) {
            el.indeterminate = checkState === null;
          }
        }}
        checked={checkState === true}
        onChange={onCheckLevelHandler}
      />
    </Col>
  )
};

const CheckLevelOptions = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="member-level-selector">
      <FormGroup>
        <Row className="no-gutters">
          {children}
          <span>{t("selectByLevel")}</span>
        </Row>
      </FormGroup>
    </div>
  );
};

/**
 * Contains one dimension and its members for selection
 * @param       {Object}   dimension           dimension data
 * @param       {Function} setMemberSelection  changes member's active property
 * @constructor
 */
function DimensionBox({ dimension, setMemberSelection }) {
  const [levels, setLevels] = useState([]);
  const [levelsState, setLevelsState] = useState(new Map());
  const totalLevels = dimension.members
    .reduce(({ level: a }, { level: b }) => ({
      level: a > b ? a : b,
    }))
    .level + 1;

  useEffect(() => {
    setLevels(
      Array(totalLevels)
        .fill()
        .map((_, idx) => idx)
    )
  }, [totalLevels]);

  const updateLevelsState = () => {
    const auxMap = new Map(levelsState);
    levels.forEach((level) => {
      const memberFromLevel = dimension.members
        .filter(({ level: levelMember }) => level === levelMember);
      const areAllChecked = memberFromLevel.every(({ active }) => active);
      const areAllUnChecked = memberFromLevel.every(({ active }) => !active);
      auxMap.set(level, areAllChecked ? true : areAllUnChecked ? false : null);
    });
    setLevelsState(auxMap);
  }

  useEffect(updateLevelsState, [levels]);

  const onCheckLevelHandler = ({ level, checked }) => {
    const associatedIds = dimension.members.filter(({ level: levelMember }) => level === levelMember)
    associatedIds.forEach(({ id }) => setMemberSelection(dimension.id, id, checked, false))
    updateLevelsState();
  }

  const setMemberSelectionHandler = (dimensionId, memberId, active, isParent) => {
    setMemberSelection(dimensionId, memberId, active, isParent);
    updateLevelsState();
  };

  return (
    <Card body className="dim-box mt-3">
      <CardTitle className="d-flex justify-content-between">
        <h6 className="dim-title">{dimension.name}</h6>

        <SelectedDropdown
          dimensionId={dimension.id}
          members={dimension.members}
          setMemberSelection={setMemberSelectionHandler}
        />
      </CardTitle>

      <MemberSelector
        key={dimension.id}
        dimensionId={dimension.id}
        members={dimension.members}
        setMemberSelection={setMemberSelectionHandler}
      >
        <CheckLevelOptions>
          {
            levelsState && levels
              .map((level) =>
                <CheckLevel
                  key={level}
                  level={level}
                  checkState={levelsState?.get(level)}
                  onChange={onCheckLevelHandler}
                />
              )
          }
        </CheckLevelOptions>
      </MemberSelector>
    </Card>
  );
}

export default DimensionBox;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import config from '../config';
import getQsParams from './getQsParams';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: config.DEFAULT_LANG,
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: { lookupQuerystring: 'lang' },
    backend: {
      loadPath: './locales/{{lng}}/{{ns}}.json'
    }
  });

export default i18n;

/**
 * returns current language
 * @return {String} language code
 */
export const getLanguage = () => {
  const lang =
    i18n.language ||
    getQsParams().lng ||
    getQsParams().lang ||
    window?.localStorage.i18nextLng ||
    config.DEFAULT_LANG;

  return lang.split('-')[0];
};

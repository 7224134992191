import qs from 'qs';

/**
 * returns paramenters received in query string
 * @return {Object}    object containing params
 */
export default function getQsParams() {
  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  return params;
}

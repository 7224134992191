import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import Api from '../../utils/Api';
import getQsParams from '../../utils/getQsParams';
import { getLanguage } from "../../utils/i18n";
import AppendExternalHtml from '../common/AppendExternalHtml';
import IndicatorSelector from '../IndicatorSelector/IndicatorSelector';
import IndicatorHeader from '../IndicatorHeader/IndicatorHeader';
import DimensionContainer from '../DimensionContainer/DimensionContainer';
import OptionContainer from '../OptionContainer/OptionContainer';
import DownloadOptions from '../DownloadOptions/DownloadOptions';
import TableContainer from '../TableContainer/TableContainer';
import processDimensions from '../../utils/data/processDimensions';
import getAllDimensions from '../../utils/data/getAllDimensions';
import config from '../../config';

import './App.scss';

const { TYPE_FLAT } = config;

const { POSITION_HEADER, POSITION_ROW, POSITION_COL } = config;

const params = getQsParams();

class App extends React.Component {
  state = {
    activeIndicatorId: null,
    indicatorsMetadata: {},
    indicatorsDimensions: {},
    indicatorsData: {},

    // position keys correspond to API position ids
    dimensionsPosition: {
      // [POSITION_HEADER]: [],
      [POSITION_ROW]: [],
      [POSITION_COL]: []
    },

    tableType: TYPE_FLAT,
    showTable: false,
    showSources: true,
    showNotes: true,
    useLetters: true
  };

  /***** lifecicle *****/

  componentDidMount() {
    if (params.indicator_id) {
      const indicatorId = parseInt(params.indicator_id);
      const areaId = params.area_id ? parseInt(params.area_id) : null;
      const members = params.members ? params.members.split(',') : null;

      this.loadIndicator(indicatorId, areaId, members);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.showTable &&
      this.state.tableType === config.TYPE_PIVOT &&
      this.positionIsEmpty()
    )
      this.setState({ showTable: false });
  }

  /***** state *****/

  setActiveIndicator = id => {
    this.setState({ activeIndicatorId: parseInt(id) });
  };

  setTableType = type => {
    this.setState({ tableType: type });
  };

  toggleSources = () => {
    this.setState(state => ({ showSources: !state.showSources }));
  };

  toggleNotes = () => {
    this.setState(state => ({ showNotes: !state.showNotes }));
  };

  toggleUseLetters = () => {
    this.setState(state => ({ useLetters: !state.useLetters }));
  };

  setDimensionsPosition = newDimensionsPosition => {
    this.setState({ dimensionsPosition: newDimensionsPosition });
  };

  positionIsEmpty = () => {
    const positions = this.state.dimensionsPosition;

    return (
      !positions[config.POSITION_ROW].length ||
      !positions[config.POSITION_COL].length
    );
  };

  /***** data handling *****/

  /**
   * fetchs indicator data from server
   * @param  {Number}    indicatorId    indicator identifier
   * @param  {Number}    areaId         area identifier
   * @param  {Number[]}  activeMembers  preselected members
   */
  loadIndicator = async (indicatorId, areaId, activeMembers) => {
    // fetch metadata
    const metadata = await Api.getMetadata(indicatorId);
    // fetch area info
    const areas = await Api.getAreas(indicatorId);
    // fetch dimensions
    let dimensions = await Api.getDimensions(indicatorId);

    if (!dimensions.length) return;

    metadata.areaInfo = areaId
      ? areas.find(area => area.area_id === areaId)
      : areas.find(
        area =>
          area.theme_id === config.PUBLIC_TREE_ID &&
          area.area_description.indexOf('INDICATOR ') === -1 &&
          area.area_description.indexOf('INDICADOR ') === -1
      );

    processDimensions(dimensions, activeMembers);

    this.setState(
      state => ({
        activeIndicatorId: indicatorId,
        indicatorsMetadata: {
          ...state.indicatorsMetadata,
          [indicatorId]: metadata
        },
        indicatorsDimensions: {
          ...state.indicatorsDimensions,
          [indicatorId]: dimensions
        },
        showTable: false
      }),
      () => {
        this.buildDimensionsPosition();

        // if members parameter is present and valid, fetch data
        activeMembers?.length && this.fetchIndicatorsData();
      }
    );
  };

  /**
   * removes indicator from app
   * @param  {Number}  indicatorId indicator identifier
   */
  removeIndicator = indicatorId => {
    let newActiveIndicatorId;

    Object.keys(this.state.indicatorsMetadata).some(id => {
      const intID = parseInt(id);

      if (intID !== this.state.activeIndicatorId) {
        newActiveIndicatorId = intID;
        return true;
      }
      return false;
    });

    this.setState(
      state => {
        delete state.indicatorsMetadata[indicatorId];
        delete state.indicatorsDimensions[indicatorId];
        delete state.indicatorsData[indicatorId];

        return {
          activeIndicatorId: newActiveIndicatorId,
          indicatorsMetadata: state.indicatorsMetadata,
          indicatorsDimensions: state.indicatorsDimensions,
          indicatorsData: state.indicatorsData
        };
      },
      () => {
        this.buildDimensionsPosition();
      }
    );
  };

  /**
   * places dimension ids into position arrays
   */
  buildDimensionsPosition = () => {
    const { indicatorsDimensions } = this.state;
    const newPositionsState = {
      [POSITION_ROW]: [],
      [POSITION_COL]: []
    };
    const allDimensions = getAllDimensions(indicatorsDimensions);

    // remove indicator dimension for single indicator
    if (Object.keys(indicatorsDimensions).length === 1)
      allDimensions[0].id === 0 && allDimensions.splice(0, 1);

    // add dimensions to corresponding position
    allDimensions.forEach(dimension => {
      if (dimension.position === POSITION_HEADER)
        // add dimension to rows, after indicator dim
        newPositionsState[POSITION_ROW].splice(1, 0, dimension.id);
      else {
        newPositionsState[dimension.position ?? POSITION_ROW].push(dimension.id);
      }
    });

    this.setState({ dimensionsPosition: newPositionsState });
  };

  /**
   * changes members selection state
   * @param {number}  dimensionId  dimension identifier
   * @param {number}  memberId     member identifier
   * @param {Boolean} isSelected   new selection state
   */
  setMemberSelection = (dimensionId, memberId, isSelected, appplyToChildren = true) => {
    const { activeIndicatorId, indicatorsDimensions } = { ...this.state };
    const activeIndicatorDimensions = indicatorsDimensions[activeIndicatorId];

    const dimensionData = activeIndicatorDimensions.find(
      (dim, i) => parseInt(dim.id) === dimensionId
    );

    dimensionData.members.forEach((member, i) => {
      if (!memberId) {
        member.active = isSelected;
      } else {
        if (parseInt(member.id) === memberId) {
          member.active = isSelected;
        }
        if (member.parent === memberId && appplyToChildren) {
          this.setMemberSelection(dimensionId, member.id, isSelected);
        }
      }
    });

    this.setState(state => ({
      indicatorsDimensions: {
        ...state.indicatorsDimensions,
        [activeIndicatorId]: activeIndicatorDimensions
      },
      showTable: false
    }));
  };

  /**
   * loops through dimensions and returns checked members for each indicator
   * @return {Object}   selected members
   */
  getSelectedMembers = () => {
    const { indicatorsDimensions } = this.state;
    let selectedMembers = {};

    // loop indicators
    Object.keys(indicatorsDimensions).forEach(indicId => {
      // loop dimensions
      indicatorsDimensions[indicId].forEach(dim => {
        // loop members
        dim.members.forEach(mem => {
          if (mem.active)
            selectedMembers[indicId]
              ? (selectedMembers[indicId] += `,${mem.id}`)
              : (selectedMembers[indicId] = `${mem.id}`);
        });
      });
    });

    return selectedMembers;
  };

  /**
   * fetches data of every indicator
   */
  fetchIndicatorsData = async () => {
    if (this.state.tableType === config.TYPE_PIVOT && this.positionIsEmpty()) {
      this.setState({ showTable: false });
      return;
    }

    let indicatorsData = {};
    const selectedMembers = this.getSelectedMembers();

    const results = await Promise.all(
      // one request for each indicator
      Object.keys(selectedMembers).map(indicId =>
        Api.getData(indicId, { members: selectedMembers[indicId], in: 1 })
      )
    );

    results.forEach(res => {
      const { indicator_id, data, metadata, sources, footnotes } = res;
      indicatorsData[indicator_id] = { data, metadata, sources, footnotes };
    });

    this.setState({ indicatorsData, showTable: true });
  };

  /***** ui *****/

  render() {
    const { t } = this.props;
    const {
      activeIndicatorId,
      indicatorsMetadata,
      indicatorsDimensions,
      indicatorsData,
      dimensionsPosition,
      showSources,
      showNotes,
      showTable,
      tableType,
      useLetters
    } = this.state;

    const indicatorsList = Object.values(indicatorsMetadata);
    const activeIndicatorMetadata = indicatorsMetadata[activeIndicatorId];
    const activeIndicatorDimensions =
      indicatorsDimensions[activeIndicatorId] || [];
    const versionNumber = process.env.REACT_APP_VERSION;

    return (
      <>
        <AppendExternalHtml url={config.HEADER_URL.replace(":language", getLanguage())} />

        <Container>
          <Row>
            <Col>
              <IndicatorSelector
                indicatorsList={indicatorsList}
                activeIndicatorId={activeIndicatorId}
                setActiveIndicator={this.setActiveIndicator}
                onAddIndicator={this.loadIndicator}
                onRemoveIndicator={this.removeIndicator}
              />
            </Col>
          </Row>

          {indicatorsList.length ? (
            <>
              <Row>
                <Col>
                  <IndicatorHeader metadata={activeIndicatorMetadata} />
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="9">
                  <DimensionContainer
                    key={activeIndicatorId}
                    dimensions={activeIndicatorDimensions}
                    setMemberSelection={this.setMemberSelection}
                  />
                </Col>
                <Col className="pl-lg-0" xs="12" lg="3">
                  <OptionContainer
                    tableType={tableType}
                    setTableType={this.setTableType}
                    dimensionsData={indicatorsDimensions}
                    dimensionsPosition={dimensionsPosition}
                    setDimensionsPosition={this.setDimensionsPosition}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DownloadOptions
                    activeIndicatorId={activeIndicatorId}
                    indicatorsDimensions={indicatorsDimensions}
                    includeSources={showSources}
                    includeNotes={showNotes}
                    useLetters={useLetters}
                    tableType={tableType}
                    anyEmptyPosition={this.positionIsEmpty()}
                    onSourcesChange={this.toggleSources}
                    onNotesChange={this.toggleNotes}
                    onUseLettersChange={this.toggleUseLetters}
                    fetchData={this.fetchIndicatorsData}
                    getSelectedMembers={this.getSelectedMembers}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {showTable ? (
                    <TableContainer
                      tableType={tableType}
                      dimensionsData={indicatorsDimensions}
                      dimensionsPosition={dimensionsPosition}
                      indicatorsData={indicatorsData}
                      showNotes={showNotes}
                      showSources={showSources}
                      useLetters={useLetters}
                    />
                  ) : null}
                </Col>
              </Row>
            </>
          ) : null}
          <small className="micro text-muted float-right mt-3">
            {t('version')} {versionNumber}
          </small>
        </Container>

        <AppendExternalHtml url={config.FOOTER_URL.replace(":language", getLanguage())} />
      </>
    );
  }
}

export default withTranslation()(App);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button } from 'reactstrap';
import config from '../../../config';

import './TableSelector.scss';

const { TYPE_FLAT, TYPE_PIVOT } = config;

/**
 * Allows to change de type of table
 * @param       {Number}   tableType    type of table
 * @param       {Function} setTableType changes the table type
 * @constructor
 */
function TableSelector({ tableType, setTableType }) {
  const { t } = useTranslation();

  return (
    <div className="table-selector d-flex justify-content-between">
      <span>{t('table')}</span>

      <ButtonGroup>
        <Button
          className="p-0"
          color="primary"
          outline
          active={tableType === TYPE_FLAT}
          onClick={() => setTableType(TYPE_FLAT)}
        >
          {t('plain')}
        </Button>

        <Button
          className="p-0"
          color="primary"
          outline
          active={tableType === TYPE_PIVOT}
          onClick={() => setTableType(TYPE_PIVOT)}
        >
          {t('pivot')}
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default TableSelector;

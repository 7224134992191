import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import scrollToElement from '../../../utils/scrollToElement';

import './TableFlat.scss';

const INDICATOR_ATTRIB = 'dim_0';

/**
 * Flat table component
 * @param {Object[]}  allDimensions  unique dimensions
 * @param {Object[]}  data           parsed data
 * @param {Boolean}   showNotes      whether to show notes column
 * @param {Boolean}   showSources    whether to show sources column
 */
const TableFlat = ({ allDimensions, data, showNotes, showSources }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    let cols = allDimensions.map(dimension => {
      const dimId = dimension.id;
      return {
        name: dimension.name,
        selector: `dim_${dimId}`,
        sortable: true,
        wrap: false,
        minWidth: '5rem',
        compact: true,
        width: dimId === 29117 ? '5rem' : null,
        center: dimId === 29117 ? true : null
      };
    });

    cols.push({
      name: t('value'),
      selector: 'value',
      sortable: true,
      right: true,
      minWidth: '5rem',
      maxWidth: '8rem',
      compact: true,
      wrap: true
      // format: row => `${row.plot.slice(0, 200)}...`
    });

    showNotes &&
      cols.push({
        name: t('notes'),
        selector: 'notes_ids',
        sortable: true,
        minWidth: '5rem',
        maxWidth: '10rem',
        center: true,
        compact: true,
        wrap: true,
        cell: row => {
          if (row.notes_ids === '') return null;

          const notesIds = row.notes_ids.split(',');

          return notesIds.map((noteId, i) => (
            <>
              <span
                className="rdt_note"
                key={i}
                onClick={() => scrollToElement(`note_${noteId}`)}
              >
                {noteId}
              </span>
              {i < notesIds.length - 1 ? <>,&nbsp;</> : null}
            </>
          ));
        }
      });

    showSources &&
      cols.push({
        name: t('sources'),
        selector: 'source_id',
        sortable: true,
        maxWidth: '5rem',
        center: true,
        compact: true,
        wrap: true,
        cell: row => (
          <span
            className="rdt_source"
            onClick={() => scrollToElement(`source_${row.source_id}`)}
          >
            {row.source_id}
          </span>
        )
      });

    return cols;
  }, [allDimensions, showNotes, showSources, t]);

  const paginationOptions = {
    rowsPerPageText: t('rowsPerPage'),
    rangeSeparatorText: t('of')
  };

  return (
    <DataTable
      className="mb-3"
      noHeader={true}
      columns={columns}
      data={data}
      dense={true}
      defaultSortField={INDICATOR_ATTRIB}
      pagination
      paginationPerPage={config.ROWS_PER_PAGE}
      paginationComponentOptions={paginationOptions}
      noDataComponent={t('noDataFound')}
    />
  );
};

export default TableFlat;

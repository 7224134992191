import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Button,
  CustomInput,
  Spinner,
  Alert
} from 'reactstrap';
import { /*MdRefresh,*/ MdFileDownload } from 'react-icons/md';
import TableToExcel from '@linways/table-to-excel';
import Api from '../../utils/Api';
import scrollToElement from '../../utils/scrollToElement';
import config from '../../config';

import './DownloadOptions.scss';

/**
 * Panel with preview and download options
 * @param       {Number}   props.activeIndicatorId     active indicator identifier
 * @param       {Object}   props.indicatorsDimensions  dimensions data
 * @param       {Object}   props.getSelectedMembers    returns all selected categories
 * @param       {Bool}     props.includeSources        show sources or not
 * @param       {Bool}     props.includeNotes          show notes or not
 * @param       {Object}   props.anyEmptyPosition      whether any position is empty
 * @param       {Function} props.fetchData             get indicators data
 * @param       {Function} props.onSourcesChange       source switch handler
 * @param       {Function} props.onNotesChange         notes switch handler
 * @param       {Bool}     props.tableType             current table type
 * @constructor
 */
function DownloadContainer(props) {
  const { t } = useTranslation();
  const infoRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);

  /**
   * verifies if all dimensions have at least one checked category
   * @return {Bool} true/false
   */
  const verifyCheckedCategories = () => {
    for (let dimId of Object.keys(props.indicatorsDimensions)) {
      for (let dimension of props.indicatorsDimensions[dimId]) {
        const activeMembers = dimension.members.find(mem => mem.active);
        if (!activeMembers) return false;
      }
    }
    return true;
  };

  const onDismiss = () => setAlertMessage('');

  const onButtonClick = format => {
    if (props.tableType === config.TYPE_PIVOT && props.anyEmptyPosition) {
      setAlertMessage(t('noEmptyPosition'));
      return;
    } else {
      alertMessage && setAlertMessage('');
    }

    if (!verifyCheckedCategories()) {
      setAlertMessage(t('atLeastOneValidation'));
      return;
    } else {
      alertMessage && setAlertMessage('');
    }

    format ? downloadFile(format) : showPreview();
  };

  const showPreview = () => {
    setLoading(true);
    props.fetchData().then(() => setLoading(false));
  };

  const downloadFile = format => {
    if (format === 'xlsx' && props.tableType === config.TYPE_PIVOT) {
      props.fetchData().then(() => {
        setLoading(false);
        const tableElem = document.getElementById('table-export');
        tableElem && TableToExcel.convert(tableElem);
      });
    } else {
      const indicId = props.activeIndicatorId;
      const selectedMembers = props.getSelectedMembers();
      const params = {
        members: selectedMembers[indicId],
        format: format
      };
      const isDownload = true;

      Api.getData(indicId, params, isDownload);
    }
  };

  // checks if empty position has been resolved
  useEffect(() => {
    if (alertMessage === t('noEmptyPosition')) {
      if (!props.anyEmptyPosition) setAlertMessage('');

      if (props.tableType === config.TYPE_FLAT) setAlertMessage('');
    }
  }, [alertMessage, props.anyEmptyPosition, props.tableType, t]);

  useEffect(() => {
    if (loading || alertMessage) scrollToElement(infoRef.current);
  }, [loading, alertMessage]);

  return (
    <Container fluid className="download-cont p-3">
      <Row className="border-bottom">
        <Col>
          <h4>{t('previewDownload')}</h4>
        </Col>
      </Row>
      <Row className="mt-3 mb-2">
        <Col className="d-flex">
          {/* include sources switch */}
          <CustomInput
            className="mr-3"
            type="switch"
            id="sources-switch"
            name="includeSources"
            label={t('includeSources')}
            checked={props.includeSources}
            onChange={props.onSourcesChange}
          />
          {/* include notes switch */}
          <CustomInput
            className="mr-3"
            type="switch"
            id="notes-switch"
            name="includeNotes"
            label={t('includeNotes')}
            checked={props.includeNotes}
            onChange={props.onNotesChange}
          />
          {/* use letters switch */}
          <CustomInput
            className="mr-3"
            type="switch"
            id="letters-switch"
            name="useLetters"
            label={t('useLetters')}
            checked={props.useLetters}
            onChange={props.onUseLettersChange}
          />
        </Col>
      </Row>
      <Row noGutters>
        <Col xs="12" md={5} className="pr-2">
          <Button
            className="w-100 mb-2"
            color="primary"
            onClick={() => onButtonClick()}
          >
            {/* // TODO:  actualizar vista previa */}
            {/* <MdRefresh className="mr-1" /> */}
            {t('preview')}
          </Button>
        </Col>
        <Col className="pr-2">
          <Button
            className="w-100 mb-2"
            color="primary"
            onClick={() => onButtonClick('xlsx')}
          >
            <MdFileDownload className="mr-1" />
            {t('xlsx')}
          </Button>
        </Col>
        <Col className="pr-2">
          <Button
            className="w-100 mb-2"
            color="primary"
            onClick={() => onButtonClick('json')}
          >
            <MdFileDownload className="mr-1" />
            {t('json')}
          </Button>
        </Col>

        <Col className="pr-2 pr-lg-0">
          <Button
            className="w-100 mb-2"
            color="primary"
            onClick={() => onButtonClick('xml')}
          >
            <MdFileDownload className="mr-1" />
            {t('xml')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div ref={infoRef}>
            {loading ? (
              <Spinner color="secondary" />
            ) : (
              <Alert color="danger" isOpen={!!alertMessage} toggle={onDismiss}>
                {alertMessage}
              </Alert>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DownloadContainer;

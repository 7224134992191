import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

import "./MemberSelector.scss";

/**
 * group of elements for selecting members
 * @param       {Number}   dimensionId         dimension identifier
 * @param       {Object[]} members             member list
 * @param       {Function} setMemberSelection  changes member's active property
 * @constructor
 */
function MemberSelector({ dimensionId, members, setMemberSelection, children }) {
  const { t } = useTranslation();

  // reference to input elements
  const refs = members.reduce((acc, member) => {
    acc[member.id] = React.createRef();
    return acc;
  }, {});

  /**
   * search input handler
   * @param  {[type]} e   event
   */
  function handleSearchChange(e) {
    const inputText = e.target.value;

    // find first matching mamber name
    const matchingMember = members.find((member) => {
      const lowercaseName = member.name.toLowerCase();
      return lowercaseName.indexOf(inputText.toLowerCase()) !== -1;
    });

    matchingMember && scrollToMember(matchingMember.id);
  }

  /**
   * scrolls members list to specific item
   * @param  {number} id   member identifier
   */
  function scrollToMember(id) {
    refs[id].current.scrollIntoView(false, {
      behavior: "smooth",
      block: "nearest", // TODO: revisar
    });
  }

  return (
    <div className="member-selector">
      {/* member list */}
      <Form onSubmit={(e) => e.preventDefault()}>
        {/* member search */}
        <Input
          type="text"
          name="buscar"
          bsSize="sm"
          placeholder={t("search") + "..."}
          onChange={handleSearchChange}
        />

        {children}

        <div className="member-list border-bottom overflow-auto p-1 my-1">
          {members.map((member, idx) => (
            <FormGroup
              check
              key={member.id}
              style={{
                marginLeft: `${member.level ? member.level * 15 : 0}px`,
              }}
            >
              <Label check>
                <Input
                  type="checkbox"
                  innerRef={refs[member.id]}
                  checked={
                    typeof member.active !== "undefined" ? member.active : false
                  }
                  onChange={(e) =>
                    setMemberSelection(dimensionId, member.id, e.target.checked)
                  }
                />{" "}
                {member.name}
              </Label>
            </FormGroup>
          ))}
        </div>

        {/* selection shortcuts */}
        <div className="shortcuts d-flex justify-content-between">
          <Button
            className="text-muted p-1"
            color="link"
            onClick={() => setMemberSelection(dimensionId, 0, true)}
          >
            {t("selectAll")}
          </Button>
          <Button
            className="text-muted p-1"
            color="link"
            onClick={() => setMemberSelection(dimensionId, 0, false)}
          >
            {t("selectNone")}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default MemberSelector;

import React from 'react';
import { Card, CardTitle } from 'reactstrap';

/**
 * Contains options of specific domain
 * @param       {String}   title      title of box
 * @param       {Object[]} children   contained elements
 * @constructor
 */
function OptionBox({ title = '', children }) {
  return (
    <Card body className="border-0 bg-transparent">
      <CardTitle className="border-bottom">
        <h6>{title}</h6>
      </CardTitle>

      {children}
    </Card>
  );
}

export default OptionBox;

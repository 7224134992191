import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { GrDrag } from 'react-icons/gr';

import './DimensionDraggable.scss';

/**
 * dimension item to be dragged and dropped inside a position area
 * @param       {Number} props.id     dimension identifier
 * @param       {String} props.name   dimension name
 * @param       {Number} props.index  index from position array
 * @param       {Bool}   props.disableDrag  whether dragging is disabled
 * @constructor
 */
function DimensionDraggable(props) {
  return (
    <Draggable
      draggableId={`${props.id}`}
      index={props.index}
      isDragDisabled={props.disableDrag}
    >
      {provided => (
        <small
          key={props.id}
          className={`dim-draggable ${
            props.disableDrag ? 'disabled' : 'bg-tertiary'
          } d-flex align-items-center rounded text-white p-1 m-1`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div>{props.name}</div>
          <GrDrag className="float-right" />
        </small>
      )}
    </Draggable>
  );
}

export default DimensionDraggable;

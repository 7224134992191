import compareDimensions from '../compareDimensions';
import processMembers from './processMembers';

/**
 * executes operations to the raw dimensions response from server
 * @param  {Object[]} dimensions         server response
 * @param  {Number[]} activeMembers    preselected members from parameter
 */
export default function processDimensions(dimensions, activeMembers) {
  // sort dimensions prioritizing transversals
  dimensions.sort(compareDimensions);

  // process dimensions' content
  dimensions.forEach(dim => {
    dim.name = _cleanTitle(dim.name);
    dim.members = processMembers(dim.members, dim.id);

    // check members parameter
    if (activeMembers) {
      let activateAll = true;
      // find matching member ids
      dim.members.forEach(mem => {
        // if found activate
        if (activeMembers.includes(mem.id.toString())) {
          activateAll = false;
          mem.active = true;
        }
      });

      // if not found, activate all
      if (activateAll) {
        dim.members.forEach(mem => (mem.active = true));
      }
    }
  });
}

// removes extra text
function _cleanTitle(dimName) {
  const title = dimName.split('__');
  title.length > 1 && title.pop();

  return title[0];
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable } from 'react-beautiful-dnd';
import { FormFeedback } from 'reactstrap';
import DimensionDraggable from '../DimensionDraggable/DimensionDraggable';

import './PositionDroppable.scss';

/**
 * Position area to drop dimensions into
 * @param       {Number}   props.id           position identifier
 * @param       {Number[]} props.dimensions   dimension list
 * @param       {Bool}     props.disableDrag  toggle dragging
 * @param       {Bool}     props.alert        toggle empty position alert message
 * @constructor
 */
function PositionDroppable(props) {
  const { t } = useTranslation();

  return (
    <div className="mb-3">
      <Droppable droppableId={`${props.id}`}>
        {provided => (
          <div
            className="pos-droppable border bg-white"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {props.dimensions.map((dimension, index) => (
              <DimensionDraggable
                key={dimension.id}
                id={dimension.id}
                name={dimension.name}
                index={index}
                disableDrag={props.disableDrag}
              />
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {props.alert && (
        <FormFeedback
          invalid="true"
          className="d-block"
          style={{ fontSize: '70%' }}
        >
          {t('noEmptyPosition')}
        </FormFeedback>
      )}
    </div>
  );
}

export default PositionDroppable;

import React, { useState, useEffect } from "react";
import config from "../../../config";

function AppendExternalHtml({ url }) {
  const [html, setHtml] = useState("");

  useEffect(() => {
    if (!html && url) {
      fetch(url)
        .then((res) => res.text())
        .then((string) => {
          string = string.replaceAll("%PUBLIC_URL%", config.PUBLIC_URL);
          setHtml(string);
        });
    }
  }, [html, url]);

  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}

export default AppendExternalHtml;

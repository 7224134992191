import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import DimensionBox from './DimensionBox/DimensionBox';

/**
 * panel containing dimension boxes
 * @param       {Object[]} dimensions         dimensions of active indicator
 * @param       {Function} setMemberSelection changes member's active property
 * @constructor
 */
function DimensionContainer({ dimensions, setMemberSelection }) {
  return (
    <Container className="bg-lightgray pb-3 mb-3">
      <Row>
        {dimensions?.map(dimension => (
          <Col sm="6" key={dimension.id}>
            <DimensionBox
              key={dimension.id}
              dimension={dimension}
              setMemberSelection={setMemberSelection}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default DimensionContainer;

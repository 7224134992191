import compareArrayValues from '../compareArrayValues';

function processMembers(members, dimensionId) {
  const findParent = parentId => members.find(mem => mem.id === parentId);

  const getLevel = (id, level) => {
    if (id === dimensionId) return level;

    return id ? getLevel(findParent(id).parent, level + 1) : level;
  };

  // add level property
  members.forEach(mem => (mem.level = getLevel(mem.parent, 0)));

  // collect top level parents
  let result = members.filter(mem => mem.level === 0);
  result.sort(compareArrayValues('order', 'asc'));

  // iterate levels and add children
  for (let lvl = 1; lvl < 100; lvl++) {
    const children = members.filter(mem => mem.level === lvl);

    if (!children.length) break;

    children.sort(compareArrayValues('order', 'desc'));

    //iterating through children and pushing below the parent
    for (let child of children) {
      const index = result.findIndex(obj => obj.id === child.parent);
      result.splice(index + 1, 0, child);
    }
  }

  return result;
}

export default processMembers;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      console.log('componentStack:', this.state.errorInfo.componentStack);

      return (
        <Alert color="danger">
          <h5>An error has occurred.</h5>
          <p> {this.state.error && this.state.error.toString()} </p>
        </Alert>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element
};

ErrorBoundary.defaultProps = {
  children: null
};

export default ErrorBoundary;
